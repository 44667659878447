export const maskRegionColors = ["red", "blue", "green", "yellow"];
export const stabilityAspectRatios = {
  "21:9": {
    title: "Ultrawide",
    height: 640,
    width: 1536,
  },
  "16:9": {
    title: "Wide",
    height: 768,
    width: 1344,
  },
  "3:2": {
    title: "Landscape",
    height: 832,
    width: 1216,
  },
  "1:1": {
    title: "Square",
    height: 1024,
    width: 1024,
  },
  "2:3": {
    title: "Portrait",
    height: 1216,
    width: 832,
  },
  "9:16": {
    title: "Narrow",
    height: 1344,
    width: 768,
  },
  // "9:21": {
  //   title: "Ultranarrow",
  //   height: 1536,
  //   width: 640,
  // },
};

export const wizardTitles = [
  {
    title: "Text to Image",
    modelCode: 1,
    modelEntity: "text_to_image",
    url: "https://corbuimgs.s3.us-west-2.amazonaws.com/images/text-2-image.png",
    label: "T",
    subTitle: "Text to Image",
    placeholderTitle: "Turn text into image.",
  },
  {
    title: "Erase & Replace",
    modelCode: 2,
    modelEntity: "diffedit",
    url: "",
    label: "ER",
    subTitle: "Erase & Replace",
    placeholderTitle: "Select an area on an image and modify it.",
  },
  {
    title: "Guided Image Render",
    modelCode: 4,
    modelEntity: "controlnet",
    url: "",
    label: "3D",
    // subTitle: "Image Render",
    subTitle: "3D Model Render",
    placeholderTitle:
      "Upload a view from a 3D Model or photo and generate concept renders.",
  },
  {
    title: "Sketch Render",
    modelCode: 3,
    modelEntity: "controlnet",
    url: "",
    label: "SR",
    subTitle: "Sketch Render",
    placeholderTitle: "Upload a sketch and generate concept renders.",
  },
  {
    title: "Guided Image Edit",
    modelCode: 3,
    modelEntity: "inpaint_controlnet",
    url: "",
    label: "SI",
    subTitle: "Sketch on Image Render",
    placeholderTitle: "AI Light Table used to trace anything into a scene.",
  },
  {
    title: "Image Enhance",
    modelCode: 5,
    modelEntity: "diffedit",
    url: "",
    label: "IE",
    subTitle: "Image Enhance",
    placeholderTitle:
      "Creative Upscaler for parts of the image or entire scene.",
  },
];

// Helper function to get workflow title
export const renderWorkflowTitle = (model_code, model_entity) => {
  for (const title of wizardTitles) {
    if (
      title.modelCode === parseInt(model_code) &&
      title.modelEntity === model_entity
    ) {
      return title.subTitle;
    }
  }
  // Handle other cases or provide a default title
  return "Workflow";
};
