// Chakra Imports
import { AiOutlinePlus } from "react-icons/ai";
import { EditOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Select,
  Heading,
  Input,
  FormLabel,
  FormControl,
  Modal,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Textarea,
  IconButton,
  useToast,
} from "@chakra-ui/react";

// Api Services Imports
import {
  addPromptData,
  updatePromptLibrary,
  fetchPromptDetails,
} from "../../../services/promptServices";

// Component Imports
import { defaultThemeColor, errorColor, greyBgColor } from "../../../constants";
import { toastFunctionToaster } from "../../../utils/toastFunction";

function PromptLibraryCrud({ setExampleImageUrl }) {
  const toast = useToast();
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, isLoading] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [promptData, setPromptData] = useState([]);
  const [promptName, setPromptName] = useState("");
  const [promptTitle, setPromptTitle] = useState("");
  const [promptNameErr, setPromptNameErr] = useState("");
  const [promptTitleErr, setPrompTitleErr] = useState("");
  const [promptGroupArr, setPromptGroupArr] = useState([]);
  const [promptGroupName, setPromptGroupName] = useState("");
  const [promptGroupNameErr, setPrompGroupNameErr] = useState("");
  const [selectedPromptName, setSelectedPromptName] = useState("");
  const [groupValue, selectedGroupValue] = useState("");
  const [updatePromptData, setUpdatePromptData] = useState({});
  const [selectedPromptRowData, setSelectedPromptRowData] = useState({});

  // Todo:On modal close reset value
  const closeModal = () => {
    onClose();
    selectedGroupValue("");
    setUpdatePromptData({});
    setPromptName("");
    setPromptTitle("");
    setGroupName("");
    setPrompTitleErr("");
    setPromptNameErr("");
    setPrompGroupNameErr("");
  };

  // validate Fields
  const validateFieldForm = () => {
    let formIsValid = true;
    if (!promptName || promptName.length <= 0) {
      formIsValid = false;
      setPromptNameErr("*Prompt Name Cannot Be Empty.");
    } else {
      setPromptNameErr("");
    }
    if (!promptTitle || promptTitle.length <= 0) {
      formIsValid = false;
      setPrompTitleErr("*Prompt Title Cannot Be Empty.");
    } else {
      setPrompTitleErr("");
    }
    if (groupValue === "Add New Group" && groupName.length <= 0) {
      formIsValid = false;
      setPrompGroupNameErr("*Group Name Cannot Be Empty.");
    } else {
      setPrompGroupNameErr("");
    }

    return formIsValid;
  };

  //   Todo:Fetch Promt Details
  useEffect(() => {
    promptDetails();
  }, []);

  //   Todo:Render Name based on group
  useEffect(() => {
    if (promptData?.length) {
      renderPromptNameDetails();
    }
  }, [promptData, promptGroupName]);

  const renderPromptNameDetails = () => {
    if (promptData?.length && promptGroupName) {
      const filteredPrompts = promptData.filter(
        (item) => item.prompt_group === promptGroupName
      );
      setPromptGroupArr(filteredPrompts);
    }
  };

  // Todo:Render Prompt Name Data
  const renderPromptNameData = () => {
    if (promptGroupArr?.length && promptGroupName?.length) {
      const filterPrompData = [
        ...new Set(promptGroupArr.filter((item) => item.prompt_group)),
      ];
      return filterPrompData.map((prompt, index) => (
        <Text
          key={index}
          cursor={"pointer"}
          py="2"
          bg={prompt?.prompt_name === selectedPromptName ? "#ecc317" : null}
          borderRadius={"10px"}
          color="#fff"
          onClick={(e) => {
            setSelectedPromptName(prompt?.prompt_name);
            setSelectedPromptRowData(prompt);
            setExampleImageUrl(prompt?.prompt_example_image?.[0]?.url || "");
          }}
          m="10px"
          p="10px"
          ml="0px"
        >
          {prompt?.prompt_name}
        </Text>
      ));
    }
  };

  const promptDetails = () => {
    isLoading(true);
    let obj = {
      records_per_page: 100,
      page_id: 1,
    };
    fetchPromptDetails(obj)
      .then((res) => {
        setSelectedPromptRowData({});
        if (res?.data[0]?.records?.length) {
          setPromptData(res?.data[0]?.records);
          setPromptGroupName(res?.data[0]?.records[0]?.prompt_group);
          setSelectedPromptName(res?.data[0]?.records[0]?.prompt_name);
          setSelectedPromptRowData(res?.data[0]?.records[0]);
          setExampleImageUrl(
            res?.data[0]?.records[0]?.prompt_example_image?.[0]?.url || ""
          );
        } else {
          setPromptData([]);
          setPromptGroupName("");
          setSelectedPromptName("");
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
        setSelectedPromptName("");
      })
      .finally(() => {
        isLoading(false);
      });
  };

  // Todo:Render Group Data
  const renderGroupData = () => {
    const uniquePromptGroups = [
      ...new Set(promptData.map((item) => item.prompt_group)),
    ];

    return uniquePromptGroups?.map((data, index) => (
      <Text
        key={index}
        cursor={"pointer"}
        py="2"
        bg={data === promptGroupName ? greyBgColor : null}
        borderRadius={"10px"}
        color="#fff"
        onClick={() => {
          setPromptGroupName(data);
          setSelectedPromptRowData({});
          const filteredPrompts = promptData.filter(
            (item) => item.prompt_group === data
          );
          setPromptGroupArr(filteredPrompts);
          if (filteredPrompts.length) {
            setSelectedPromptName(filteredPrompts[0].prompt_name);
            setSelectedPromptRowData(filteredPrompts[0]);
            setExampleImageUrl(
              filteredPrompts[0]?.prompt_example_image?.[0]?.url || ""
            );
          }
        }}
        m="10px"
        p="10px"
        ml="0px"
      >
        {data}
      </Text>
    ));
  };

  const renderGroupList = () => {
    if (promptData?.length) {
      const uniquePromptGroups = [
        ...new Set(promptData.map((item) => item.prompt_group)),
      ];
      return uniquePromptGroups?.map((value, index) => (
        <option key={index} color="#000" value={value}>
          {value}
        </option>
      ));
    }
  };

  // Todo: Add prompt api to save data to prompt library
  const saveData = () => {
    if (validateFieldForm()) {
      isLoading(true);
      let obj = {
        prompt_group: groupName?.length ? groupName : groupValue,
        prompt_name: promptName,
        prompt_text: promptTitle,
      };
      if (updatePromptData && Object.keys(updatePromptData).length) {
        obj["prompt_uuid"] = updatePromptData?.prompt_uuid;
        updatePromptLibraryData(obj);
      } else {
        addPromptData(obj)
          .then((res) => {
            isLoading(false);
            if (res?.result && res?.data?.length) {
              promptDetails();
              closeModal();
              toast(toastFunctionToaster(res?.message, "success"));
            } else {
              toast(toastFunctionToaster(res?.message, "error"));
            }
          })
          .catch((err) => {
            isLoading(false);
          });
      }
    }
  };

  // Todo: Update prompt api to save data to prompt library
  const updatePromptLibraryData = (obj) => {
    obj.prompt_uuid = updatePromptData?.prompt_uuid;
    updatePromptLibrary(obj)
      .then((res) => {
        isLoading(false);
        if (res?.result && res?.data?.length) {
          promptDetails();
          closeModal();
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        isLoading(false);
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      });
  };

  return (
    <Box h="100%" overflow={"hidden"}>
      <Flex justifyContent={"space-between"} alignItems={"center"} h="7%">
        <Heading color="#fff" fontWeight="600" fontSize="24px">
          Prompt Library
        </Heading>
        <Button size="sm" onClick={onOpen} rightIcon={<AiOutlinePlus />}>
          Add New Prompt
        </Button>
      </Flex>

      {loading ? (
        <Flex alignItems="center" justifyContent="center" h="60vh" w="100%">
          <Spinner size="xl" color="#fff" />
        </Flex>
      ) : (
        <Box bg="#151117" h="90%" borderRadius={"10px"} p="4" mt="3">
          {promptData?.length ? (
            <Flex justifyContent={"space-between"}>
              <Box w="32%" key="group">
                <Text color="#fff" fontSize={"16px"}>
                  Group
                </Text>
                <Box
                  h="calc(100vh - 175px)"
                  overflow="auto"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "24px",
                    },
                  }}
                  mt="3"
                  borderRightWidth="1px"
                  borderRightColor={greyBgColor}
                >
                  {renderGroupData()}
                </Box>
              </Box>
              <Box w="32%" key="name">
                <Text color="#fff" fontSize={"16px"}>
                  Name
                </Text>
                <Box
                  h="calc(100vh - 175px)"
                  overflow="auto"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "24px",
                    },
                  }}
                  mt="3"
                  borderRightWidth="1px"
                  borderRightColor={greyBgColor}
                >
                  {renderPromptNameData()}
                </Box>
              </Box>
              <Box w="32%" key="text">
                <Text color="#fff" fontSize={"16px"}>
                  Prompt
                </Text>
                {Object.keys(selectedPromptRowData).length ? (
                  <Box h="75vh" mt="3">
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Text color="#fff">
                        {selectedPromptRowData?.prompt_text}
                      </Text>
                      <IconButton
                        colorScheme="yellow"
                        borderRadius="20px"
                        size="xs"
                        icon={<EditOutlined />}
                        onClick={() => {
                          onOpen();
                          setUpdatePromptData(selectedPromptRowData);
                          setGroupName(selectedPromptRowData?.prompt_group);
                          setPromptName(selectedPromptRowData?.prompt_name);
                          setPromptTitle(selectedPromptRowData?.prompt_text);
                          selectedGroupValue(
                            selectedPromptRowData?.prompt_group
                          );
                        }}
                      />
                    </Flex>
                  </Box>
                ) : null}
              </Box>
            </Flex>
          ) : (
            <Text fontSize="16px" color={defaultThemeColor}>
              You haven't created any prompts yet. Please create a new prompt by
              selecting "Create New Prompt".
            </Text>
          )}
        </Box>
      )}
      {/* Popup Model To Add/Update Prompt */}
      <Modal
        finalFocusRef={finalRef}
        size="xl"
        isOpen={isOpen}
        onClose={() => {
          closeModal();
        }}
      >
        <ModalOverlay />
        <ModalContent bg={greyBgColor} borderRadius="20px">
          <ModalHeader color="#fff">
            {Object.keys(updatePromptData).length ? "Update" : "Add"} Prompt
          </ModalHeader>
          <ModalCloseButton color="#d7d7d7" />
          <ModalBody>
            <FormControl>
              <FormLabel fontSize={"14px"} fontWeight={"normal  "} color="#fff">
                Group
              </FormLabel>
              {groupValue === "Add New Group" ? (
                <>
                  <Input
                    border="none"
                    bg="#151117"
                    fontSize="14px"
                    h="45px"
                    borderRadius={"10px"}
                    placeholder="Enter Group Name"
                    value={groupName}
                    onChange={(e) => {
                      setGroupName(e.target.value);
                    }}
                    _focus={{ boxShadow: "none" }}
                    color="#fff"
                  />
                </>
              ) : (
                <Select
                  _focus={{ boxShadow: "none" }}
                  border="none"
                  bg="#151117"
                  fontSize="14px"
                  h="45px"
                  borderRadius={"10px"}
                  value={groupValue}
                  onChange={(e) => {
                    selectedGroupValue(e.target.value);
                  }}
                  color="#606c7f"
                >
                  <option color="#000" value="" disabled selected>
                    Select Group
                  </option>

                  {renderGroupList()}
                  <option value="Add New Group">Add New Group</option>
                </Select>
              )}
              {promptGroupNameErr?.length ? (
                <Text fontSize="sm" color={errorColor} mt="1">
                  {promptGroupNameErr}
                </Text>
              ) : null}
            </FormControl>
            <FormControl mt="4">
              <FormLabel fontSize={"14px"} fontWeight={"normal  "} color="#fff">
                Prompt Name
              </FormLabel>

              <Input
                border="none"
                bg="#151117"
                fontSize="14px"
                h="45px"
                borderRadius={"10px"}
                value={promptName}
                onChange={(e) => {
                  setPromptName(e.target.value);
                }}
                placeholder="Enter Prompt Name"
                _focus={{ boxShadow: "none" }}
                color="#fff"
              />
              {promptNameErr?.length ? (
                <Text fontSize="sm" color={errorColor} mt="1">
                  {promptNameErr}
                </Text>
              ) : null}
            </FormControl>
            <FormControl mt="4">
              <FormLabel fontSize={"14px"} fontWeight={"normal  "} color="#fff">
                Prompt
              </FormLabel>
              <Textarea
                border="none"
                bg="#151117"
                fontSize="14px"
                h="45px"
                borderRadius={"10px"}
                placeholder="Enter Prompt "
                value={promptTitle}
                onChange={(e) => {
                  setPromptTitle(e.target.value);
                }}
                _focus={{ boxShadow: "none" }}
                color="#fff"
              />
              {promptTitleErr?.length ? (
                <Text fontSize="sm" color={errorColor} mt="1">
                  {promptTitleErr}
                </Text>
              ) : null}
            </FormControl>
            <Box mt="4">
              <Text fontSize={"14px"} fontWeight={"normal  "} color="#fff">
                Upload Image
              </Text>
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                mt="2"
                borderWidth={"1px"}
                borderStyle={"dotted"}
                bg={"#151117"}
                h="120px"
                borderColor={"#fff"}
                color="#fff"
                borderRadius={"10px"}
                _hover={{ bg: "black" }}
                cursor={"pointer"}
              >
                Upload Image
              </Flex>{" "}
            </Box>
          </ModalBody>

          <ModalFooter pb="25px">
            <Button
              w="100%"
              colorScheme="green"
              size="sm"
              onClick={() => {
                saveData();
              }}
              isDisabled={loading || groupValue === "" ? true : false}
              isLoading={loading ? true : false}
            >
              {Object.keys(updatePromptData).length ? "Update" : "Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default PromptLibraryCrud;
