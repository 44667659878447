import React, { useEffect, useState, useRef } from "react";
import axios from "../../../services/axios";
import {
  Box,
  Text,
  Button,
  Input,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import {
  resourceLabellerRequest,
  fetchObjectUploadUrl,
} from "../../../services/resourceTemplateService";
import {
  defaultGreyColor,
  defaultThemeColor,
  greyBgColor,
} from "../../../constants";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import ImageCompressorModal from "./ImageCompressorModal";

const ObjectUploader = (props) => {
  const toast = useToast();
  const [loading, isLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const resource_uuid = props.createObject.resource_uuid;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageHeight, setImageHeight] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);
  const [cropImageFlag, setCropImageFlag] = useState(null);
  const fileInputRef = useRef(null);

  // Todo:Paste Event
  const handlePaste = (event) => {
    if (
      (event.ctrlKey || event.metaKey) &&
      event.key === "v" &&
      props?.clipImage &&
      document.activeElement.tagName !== "INPUT" &&
      document.activeElement.tagName !== "TEXTAREA"
    ) {
      let obj = {
        key: props?.clipImage,
      };
      props.objectUploaded(obj);
    }
  };

  const handleMouseEnter = () => {
    document.addEventListener("keydown", handlePaste);
  };

  const handleMouseLeave = () => {
    document.removeEventListener("keydown", handlePaste);
  };

  const postObjectData = async (s3Data, selectedFile) => {
    const { url, fields } = s3Data;
    const payload = new FormData();

    // add all the other fields
    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    // payload.append("Content-Type", "image/jpeg");
    // This must be the last line in the payload otherwise 412 error may occur
    // this is the file blob, eg from <input type="file">
    payload.append("file", selectedFile);

    // No need to manually set content-type header, your browser knows what to do
    try {
      await axios.post(url, payload);
      props.objectUploaded(fields);
      toast(toastFunctionToaster("File is uploaded successfully", "success"));
      resourceLabellerRequest({
        resource_uuid: resource_uuid,
        labeler_json: JSON.stringify({
          model_sub_name: "index",
          exclude_dirs: ["output/"],
        }),
      })
        .then((res) => {
          if (res?.data?.length > 0 && res?.result) {
            toast(
              toastFunctionToaster(
                "Image search index is updating...",
                "success"
              )
            );
          } else {
            toast(
              toastFunctionToaster(
                "Error occured while updating image search index...",
                "error"
              )
            );
          }
        })
        .catch((err) => {
          toast(toastFunctionToaster(err, "error"));
        });
    } catch (error) {
      toast(toastFunctionToaster("Something Went Wrong", "error"));
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = async () => {
          const maxDimension = Math.max(image.width, image.height);
          if (file) {
            setUploadFile(file);
            setImageHeight(image.height);
            setImageWidth(image.width);
          } else {
            setUploadFile(null);
            setImageHeight(null);
            setImageWidth(null);
          }
          if (maxDimension > 3072 || cropImageFlag) {
            onOpen();
          } else {
            await handleSubmitNew(file);
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitNew = async (uploadFile) => {
    isLoading(true);
    let objData = {
      resource_uuid: resource_uuid,
      file_name: uploadFile.name,
      file_type: uploadFile.type,
      resource_choice: "object",
    };
    try {
      const response = await fetchObjectUploadUrl(objData);
      if (
        response &&
        response?.data &&
        response?.data?.length > 0 &&
        response.data[0]?.signedPackage?.signedUrl
      ) {
        const finalUrl = response.data[0]?.signedPackage?.signedUrl;
        await postObjectData(finalUrl, uploadFile);
      } else {
        toast(toastFunctionToaster(response?.message, "error"));
      }
    } catch (error) {
      toast(toastFunctionToaster("Something Went Wrong", "error"));
    } finally {
      isLoading(false);
      setUploadFile(null);
      setImageHeight(null);
      setImageWidth(null);
    }
  };

  // Todo:Popup Modal Closed
  const closeModal = () => {
    onClose();
    setCropImageFlag(null);
    fileInputRef.current.value = null;
  };

  const handleButtonClick = () => {
    setCropImageFlag(true);
    fileInputRef.current.value = null;
    fileInputRef.current.click(); // Trigger file input click
  };

  return (
    <>
      {resource_uuid === null || resource_uuid === undefined ? (
        <Text color={"red:500"}>Error...</Text>
      ) : (
        <Box
          h={props?.height ? props?.height : "180px"}
          borderWidth={"1px"}
          borderStyle={"dashed"}
          borderColor={defaultGreyColor}
          borderRadius={"10px"}
          position={"relative"}
          bg={greyBgColor}
          _hover={{ bg: "#151516" }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {props?.clipImage?.length ? (
            <Text
              fontWeight={"600"}
              borderTopLeftRadius={"10px"}
              borderTopRightRadius={"10px"}
              textAlign={"center"}
              color={defaultThemeColor}
            >
              Control + V To Paste Copied Image
            </Text>
          ) : null}
          <Input
            ref={fileInputRef}
            p="0px"
            type="file"
            onChange={(e) => {
              handleFileChange(e);
            }}
            accept=".jpg,.jpeg,.png"
            position="relative"
            opacity="0"
            h="100%"
            zIndex={"1"}
            cursor={"pointer"}
          ></Input>
          <Button
            mt={2}
            size={props?.size ? props?.size : "sm"}
            // onClick={handleSubmitNew}
            position="absolute"
            top={props?.showCropButton ? "30%" : "50%"}
            left="50%"
            transform="translate(-50%, -50%)"
            colorScheme="green"
            textColor={"#fff"}
            isDisabled={loading ? true : false}
            isLoading={loading ? true : false}
          >
            Upload Image
          </Button>
          {props?.showCropButton ? (
            <Button
              mt={2}
              size={props?.size ? props?.size : "sm"}
              onClick={() => {
                handleButtonClick();
              }}
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              colorScheme="green"
              textColor={"#fff"}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
              zIndex={"1"}
            >
              Upload & Crop Image
            </Button>
          ) : null}
        </Box>
      )}
      <ImageCompressorModal
        handleSubmitNew={handleSubmitNew}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        isModalOpen={isOpen}
        onModalClose={closeModal}
        setUploadedImage={setUploadFile}
        uploadedImage={uploadFile}
        imageLoading={loading}
        cropImageStatus={cropImageFlag}
      />
    </>
  );
};

export default ObjectUploader;
