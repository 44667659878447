// React Chakra Imports
import { CgAsterisk } from "react-icons/cg";
import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

// Component Imports
import { errorColor } from "../../../../../constants";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";

// Api Imports
import {
  createSharedSpace,
  updateShareSpace,
} from "../../../../../services/businessServices";

function CreateShareSpace({
  project_uuid,
  shareSpaceData,
  renderSpaceData,
  updateShareData,
}) {
  const toast = useToast();
  const btnRef = React.useRef();
  const [error, setError] = useState(false);
  const [loading, isLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sharedSpaceName, setShareSpaceName] = useState("");
  const [updatedShareSpaceData, setUpdatedShareSpaceData] = useState({});

  // Syncing shareSpaceData on change
  useEffect(() => {
    if (shareSpaceData) {
      setUpdatedShareSpaceData(shareSpaceData);
      setShareSpaceName(shareSpaceData?.name || "");
    }
  }, [shareSpaceData]);

  // Open drawer when updated data is set
  useEffect(() => {
    if (updatedShareSpaceData?.name) {
      onOpen();
    }
  }, [updatedShareSpaceData, onOpen]);

  const handleSharedSpace = () => {
    if (!sharedSpaceName) {
      setError("Shared space name is required");
    } else {
      setError("");
      saveSharedSpaceData();
    }
  };

  const handleClose = () => {
    onClose();
    setError("");
    updateShareData({});
    setShareSpaceName("");
    setUpdatedShareSpaceData({});
  };

  // Todo:Save Shared Data
  const saveSharedSpaceData = () => {
    isLoading(true);
    let obj = {
      project_uuid: project_uuid,
      shared_space_name: sharedSpaceName,
    };
    if (updatedShareSpaceData?.shared_space_uuid) {
      delete obj.project_uuid;
      obj.shared_space_uuid = updatedShareSpaceData?.shared_space_uuid;
      updateShareSpace(obj)
        .then((res) => {
          isLoading(false);
          renderSpaceData(true);
          if (res?.result) {
            toast(toastFunctionToaster(res?.message, "success"));
            handleClose();
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
            handleClose();
          }
        })
        .catch((err) => {
          isLoading(false);
          if (err) {
            handleClose();
            renderSpaceData(false);
            toast(toastFunctionToaster(err?.message, "error"));
          }
        });
    } else {
      createSharedSpace(obj)
        .then((res) => {
          isLoading(false);
          renderSpaceData(true);
          if (res?.result) {
            handleClose();
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            handleClose();
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          isLoading(false);
          if (err) {
            toast(toastFunctionToaster(err?.message, "error"));
          }
        });
    }
  };

  return (
    <>
      <Button size={"sm"} colorScheme="purple" onClick={onOpen} mr="2">
        Create Shared Space
      </Button>
      <Drawer
        onClose={() => {
          handleClose();
        }}
        size="md"
        isOpen={isOpen}
        placement="right"
        finalFocusRef={btnRef}
        fontFamily="Poppins"
      >
        <DrawerOverlay />
        <DrawerContent bg="#151117">
          <DrawerCloseButton color={"red.500"} />
          <DrawerHeader
            color={"#fff"}
            borderBottomWidth={"0.5px"}
            borderBottomColor={"#686868"}
          >
            {updatedShareSpaceData?.shared_space_uuid ? "Update" : "Create"}{" "}
            Shared Space
          </DrawerHeader>

          <DrawerBody mt="4">
            <Box>
              <Flex>
                <Text mb="8px" color={"#fff"}>
                  Shared Space Name
                </Text>
                <CgAsterisk color={errorColor} />
              </Flex>
              <Input
                size="md"
                mt="3"
                bg="#1c181e"
                color="#fff"
                fontSize="14px"
                borderRadius="5px"
                borderColor="#494552"
                value={sharedSpaceName}
                _placeholder={{ color: "white" }}
                placeholder="Enter Shared Space Name"
                onChange={(e) => {
                  setShareSpaceName(e.target.value);
                }}
              />
              {error ? (
                <Text fontSize="sm" color={errorColor} mt="1">
                  {error}
                </Text>
              ) : null}
            </Box>
          </DrawerBody>

          <DrawerFooter borderTopWidth={"0.5px"} borderTopColor={"#686868"}>
            <Button colorScheme="red" size="sm" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              size="sm"
              onClick={() => {
                handleSharedSpace();
              }}
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
            >
              {updatedShareSpaceData?.shared_space_uuid ? "Update" : "Save"}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CreateShareSpace;
