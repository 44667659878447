// React Chakra Import
import { React, useState, useRef, useEffect } from "react";
import {
  Modal,
  Input,
  Button,
  HStack,
  useToast,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";

import { externalContentList } from "../../services/sharedServices";
import { toastFunctionToaster } from "../../utils/toastFunction";

function SharedSpaceAuth({ sharedSpaceKey, email, setEmail, code, setCode, setIsCodeCorrect }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [emailInput, setEmailInput] = useState("");
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleEmailSubmit = (inputEmail) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(inputEmail)) {
      setEmailInput("");
      toast(toastFunctionToaster("Please enter a valid email.", "error"));
      return;
    }
    const params = {
      shared_space_key: sharedSpaceKey,
      user_email: inputEmail,
    };
    const obj = {
      records_per_page: 0,
      page_id: 1,
    };
    externalContentList(params, obj)
      .then((res) => {
        if (res.result) {
          setIsCodeCorrect(true);
          onClose();
        } else {
          toast({title: res?.message, status: "error", isClosable: true, duration: 60000 });
          if (res?.message === "Please check your email inbox for the passcode required to view this shared space.") {
            setEmail(inputEmail);
            setInputValues(["", "", "", ""]);
            inputRefs[0].current.focus();
          } else {
            setEmailInput(email);
          }
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something went wrong...", "error"));
      });
  };

  // External Content
  const handleCheckCode = () => {
    const enteredCode = inputValues.join("");
    const params = {
      shared_space_key: sharedSpaceKey,
      user_email: email,
      password: enteredCode,
    };
    const obj = {
      records_per_page: 0,
      page_id: 1,
    };
    externalContentList(params, obj)
      .then((res) => {
        if (res.result) {
          setIsCodeCorrect(true);
          setCode(enteredCode);
          onClose();
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
          setInputValues(["", "", "", ""]);
          inputRefs[0].current.focus();
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something went wrong...", "error"));
      });
  };

  // Handle input change for each digit
  const handleChange = (value, index) => {
    if (value.length > 1) return;
    const newValues = [...inputValues];
    newValues[index] = value;
    setInputValues(newValues);
    if (value && index < 3) {
      inputRefs[index + 1].current.focus();
    } else if (!value && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  useEffect(() => {
    if (inputValues?.join("")?.length === 4) {
      handleCheckCode();
    }
  }, [inputValues]);

  useEffect(() => {
    if (code) {
      const splitCode = code?.split("");
      const newValues = [...inputValues];
      splitCode?.forEach((data, index) => {
        newValues[index] = data;
      });
      setInputValues(newValues);
    } else if (email) {
      handleEmailSubmit(email);
    }
  }, [code, email]);

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {email ? "Please Enter Your 4 Digit Passcode" : "Please Enter Your Email"}
        </ModalHeader>
        <ModalBody>
          {email ? (
            <HStack spacing={4} justify="center">
              {inputValues.map((value, index) => (
                <Input
                  key={index}
                  ref={inputRefs[index]}
                  value={value}
                  onChange={(e) => handleChange(e.target.value, index)}
                  maxLength={1}
                  textAlign="center"
                  type="number"
                  size="lg"
                  w="50px"
                />
              ))}
              <Button mt={4} onClick={() => {handleEmailSubmit(email)}} colorScheme="red">
                Resend Code
              </Button>
            </HStack>
          ) : (
            <HStack spacing={4} justify="center">
              <Input
                placeholder="Email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
              />
              <Button mt={4} onClick={() => {handleEmailSubmit(emailInput)}} colorScheme="blue">
                Check Email
              </Button>
            </HStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
export default SharedSpaceAuth;