// React Chakra Imports
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { AiFillEdit, AiOutlineSearch } from "react-icons/ai";
import ResponsivePagination from "react-responsive-pagination";
import {
  Box,
  Tab,
  Tabs,
  Grid,
  Flex,
  Icon,
  Text,
  Input,
  Button,
  HStack,
  VStack,
  Spinner,
  GridItem,
  useToast,
  Heading,
  TabList,
  TabPanel,
  TabPanels,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

// Component Imports
import AddProjectTemplate from "./AddProjectTemplate";
import "react-responsive-pagination/themes/classic.css";
import SliderImage from "../../../image/demo-sofa-image.jpg";
import ProjectWorkflow from "./ProjectWorkflow/ProjectWorkflow";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { resetWorkflowData } from "../../../utils/resetWorkflowData";
import ProjectShare from "./ProjectWorkflow/ProjectShare/ProjectShare";
import { setDeseignProjectObj } from "../../../store/actions/workFlowAction";
import CreateShareSpace from "./ProjectWorkflow/ProjectShare/CreateShareSpace";
import {
  defaultThemeColor,
  themeFontSizeOddHeading,
  themeFontSizeSmallHeading,
} from "../../../constants/index";

// API Imports
import { fetchProjListingServices } from "../../../services/projectTemplateService";

function ProjectManager() {
  const targetRef = useRef({}); // Scroll To Selected Project Reference
  const dispatch = useDispatch();
  const toast = useToast();
  const recordsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [project_uuid, setProject_uuid] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [projectDetails, setProjectDetails] = useState({});
  const [viewUpdateFlag, setViewUpdateFlag] = useState("");
  const [editShareSpaceData, setEditShareSpaceData] = useState({});
  const [renderShareSpaceData, setRenderShareSpaceData] = useState(false);
  const [renderCounter, setRenderCounter] = useState(0);
  const [filterProjectName, setFilterProjectName] = useState("");
  const [onlyEnabledFilter, setOnlyEnabledFilter] = useState(true);
  const [fullProjectDetails, setFullProjectDetails] = useState([]);
  const [selectedTemplateData, setSelectedTemplateData] = useState({});
  const [filteredProjectDetails, setFilteredProjectDetails] = useState([]);
  const emptyProjectsListErr =
    'You haven\'t created any projects yet. Please add a new project by selecting "Create New Project".';

  const workflowDetails = useSelector((store) => store.workflowDesignHeader);

  useEffect(() => {
    projectTemplateList();
  }, [onlyEnabledFilter, currentPage]);

  // Project Id Set Globally
  useEffect(() => {
    if (project_uuid) {
      const obj = {
        projectId: project_uuid,
        currentPage: currentPage,
      };
      dispatch(setDeseignProjectObj(obj));
    }
  }, [project_uuid]);

  // useEffect(() => {
  //   const designProjectDetails = workflowDetails?.designProjectObj;
  //   setProject_uuid(designProjectDetails?.projectId);
  //   setCurrentPage(designProjectDetails?.currentPage);
  //   setTimeout(() => {
  //     scrollToTarget();
  //   }, 1000);
  // }, [workflowDetails?.designProjectObj]);

  // Function to handle the scroll action
  const scrollToTarget = () => {
    if (targetRef.current[project_uuid]) {
      targetRef.current[project_uuid].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  // Todo:Fetch Project Details
  const projectTemplateList = () => {
    let objBody = {
      searchKey: "",
      searchValue: "",
      filterEnabled: String(onlyEnabledFilter),
      records_per_page: recordsPerPage,
      page_id: currentPage,
    };
    setLoading(true);
    resetWorkflowData(dispatch);
    fetchProjListingServices(objBody)
      .then((res) => {
        if (res?.data[0]?.total_records > 0) {
          setFullProjectDetails(res.data[0]?.records);
          setFilteredProjectDetails(res?.data[0]?.records);
          setProject_uuid(res.data[0]?.records[0].project_uuid);
          setProjectDetails(res?.data[0]?.records[0]);
          setTotalPages(res?.data[0]?.total_pages);
        } else {
          setErrorMessage(emptyProjectsListErr);
          setFullProjectDetails([]);
        }
      })
      .catch((err) => {
        if (err) {
          toast(toastFunctionToaster(err.message, "error"));
        }
        setFullProjectDetails([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Todo:Render Project Details
  const renderFullProjectsList = () => {
    if (filteredProjectDetails && filteredProjectDetails.length > 0) {
      return filteredProjectDetails.map((data, index) => (
        <GridItem
          key={index}
          ref={(el) => (targetRef.current[data?.project_uuid] = el)}
        >
          <Box
            m="1"
            p="10px"
            bgImage={
              data?.project_cover?.length
                ? data?.project_cover[0].url
                : SliderImage
            }
            bgRepeat="no-repeat"
            bgSize="cover"
            _hover={{ cursor: "pointer" }}
            h="150"
            w="95%"
            borderRadius="10px"
            borderWidth={data?.project_uuid === project_uuid ? "3px" : null}
            borderColor={
              data?.project_uuid === project_uuid ? defaultThemeColor : null
            }
            onClick={() => {
              setProject_uuid(data.project_uuid);
              setProjectDetails(data);
            }}
          >
            <Text textAlign="right" w="auto">
              <Text
                as="span"
                color="black"
                bg={defaultThemeColor}
                fontSize={"10px"}
                p="1"
                borderRadius="10px"
                fontWeight="600"
              >
                {data?.project_type ? data.project_type : "-"}
              </Text>
            </Text>
          </Box>
          <Flex pl={2} justifyContent="space-between">
            <Box w={"95%"}>
              <Text mb="5px" fontSize={16} color="#fff">
                <HStack justifyContent="space-between">
                  <Text fontSize={themeFontSizeSmallHeading} fontWeight="600">
                    {data.project_name ? data.project_name : "-"}
                  </Text>
                  <Button
                    size="xs"
                    colorScheme="yellow"
                    borderRadius="0px"
                    fontWeight="normal"
                    fontSize={themeFontSizeOddHeading}
                    rounded={"md"}
                    onClick={() => {
                      setSelectedTemplateData(data);
                      setViewUpdateFlag("editProjTemplate");
                    }}
                  >
                    <Icon w={3} h={3} as={AiFillEdit}></Icon>
                  </Button>
                </HStack>
                <Text fontSize={themeFontSizeOddHeading} fontWeight="normal">
                  {data.project_details}
                </Text>
              </Text>
            </Box>
          </Flex>
        </GridItem>
      ));
    } else {
      return (
        <Box
          border="1px"
          borderRadius="10px"
          bg="#151117"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          p="10"
        >
          <Text
            color={defaultThemeColor}
            fontWeight="bold"
            textAlign={"center"}
          >
            {errorMessage ? errorMessage : "No Projects Found"}
          </Text>
        </Box>
      );
    }
  };

  // Todo:Reset Project data if popup-closed
  const resetUpdatedProjFormData = (data, renderFlag) => {
    if (data === true) {
      setSelectedTemplateData({});
      setViewUpdateFlag("");
      if (!renderFlag) {
        setCurrentPage(1);
      }
    }
    if (data === "renderProjData") {
      setSelectedTemplateData({});
      setViewUpdateFlag("");
      projectTemplateList();
      setCurrentPage(1);
    }
  };

  // Todo:Filter Search Data From Project Details
  const filterProjectDetails = () => {
    const cloneFullProjectDetails = [...fullProjectDetails];
    let filteredData = [];
    if (filterProjectName?.length) {
      filteredData = cloneFullProjectDetails.filter((item) =>
        item?.project_name
          ?.toLowerCase()
          .includes(filterProjectName.toLowerCase())
      );
    } else {
      filteredData = cloneFullProjectDetails;
    }
    setFilteredProjectDetails(filteredData);
  };

  useEffect(() => {
    filterProjectDetails();
  }, [filterProjectName]);

  // Todo:To Re-render update counter once space is created / updated
  const handleData = (data) => {
    if (data) {
      setRenderShareSpaceData(data);
      setRenderCounter((prev) => prev + 1);
    }
  };

  return (
    <Box>
      <Flex>
        {loading ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            mt="10"
            h="50vh"
            w="100%"
          >
            <Spinner size="xl" color="#fff" />
          </Flex>
        ) : (
          <Flex w="100%">
            <VStack w={project_uuid?.length ? "20%" : "100%"}>
              <Box w="100%">
                <Heading mb="5" color="#fff" fontWeight="600" fontSize="24px">
                  Design Projects
                </Heading>
                <Box>
                  <InputGroup border="none">
                    <InputLeftElement pointerEvents="none">
                      <AiOutlineSearch color="#fff" />
                    </InputLeftElement>
                    <Input
                      type="text"
                      color="#fff"
                      placeholder="Search Projects By Name"
                      borderColor={"#606060"}
                      _hover={{ boxShadow: "none" }}
                      onChange={(e) => {
                        setFilterProjectName(e.target.value);
                      }}
                    />
                  </InputGroup>
                </Box>
              </Box>
              <Box
                w={"100%"}
                mb="5"
                mt="2"
                h="calc(100vh - 230px)"
                overflow="auto"
                overflowX="hidden"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#fff",
                    borderRadius: "24px",
                  },
                }}
              >
                <Grid
                  templateColumns={
                    project_uuid?.length ? "repeat(1, 1fr)" : "repeat(4, 1fr)"
                  }
                  gap={2}
                >
                  <Box>
                    <AddProjectTemplate
                      sendEditableData={selectedTemplateData}
                      resetEditableData={resetUpdatedProjFormData}
                      projectEditViewFlag={viewUpdateFlag}
                    />
                  </Box>
                  {renderFullProjectsList()}
                </Grid>
              </Box>
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={(pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
              />
            </VStack>

            <Tabs w="80%" onChange={(index) => setSelectedIndex(index)}>
              <TabList borderBottomWidth={"0px"} pl="5">
                <Tab
                  bg={"#000"}
                  color="white"
                  _selected={{
                    color: "white",
                    bg: "green.400",
                    fontWeight: "bold",
                  }}
                >
                  Project
                </Tab>
                <Tab
                  w="100%"
                  borderBottom={"0px"}
                  _focus={{ boxShadow: "none" }}
                  // ml="3"
                  _active={{ boxShadow: "none" }}
                  hover={{ boxShadow: "none" }}
                  p="0"
                  bg={"#000"}
                  color="white"
                >
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    w="100%"
                  >
                    <Text
                      p="12px"
                      as="span"
                      bg={selectedIndex === 1 ? "green.400" : "#000"}
                      fontWeight={selectedIndex === 1 ? "bold" : "normal"}
                      color="#fff"
                    >
                      Share
                    </Text>
                    {selectedIndex === 1 && (
                      <CreateShareSpace
                        project_uuid={project_uuid}
                        shareSpaceData={editShareSpaceData}
                        updateShareData={setEditShareSpaceData}
                        renderSpaceData={handleData}
                      />
                    )}
                  </Flex>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel pr="0">
                  {project_uuid?.length ? (
                    <Box w={"100%"} borderRadius={"10px"}>
                      <ProjectWorkflow
                        project_uuid={project_uuid}
                        projectDetails={projectDetails}
                        resetEditableData={resetUpdatedProjFormData}
                      />
                    </Box>
                  ) : null}
                </TabPanel>
                <TabPanel pr="0">
                  <Box w={"100%"} borderRadius={"10px"}>
                    <ProjectShare
                      selectedIndex={selectedIndex}
                      projectId={project_uuid}
                      renderData={renderCounter}
                      editSpaceData={setEditShareSpaceData}
                    />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

export default ProjectManager;
