// Chakra - React Imports
import { React, useState, useEffect } from "react";

import {
  Avatar,
  Box,
  Img,
  Text,
  Grid,
  Flex,
  Link,
  Heading,
  Switch,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";

// Component Imports
import { greyBgColor } from "../../constants";
import { toastFunctionToaster } from "../../utils/toastFunction";
import UploadSharedContent from "../Business/ProjectManagement/ProjectWorkflow/ProjectShare/UploadSharedContent";

// Api Imports
import { externalContentList } from "../../services/sharedServices";
import { fetchSharedSpaceData, handleSharedContentStatus } from "../../services/businessServices";

function SharedFolder({ type, shared_space_id, email, code, filterEnabledFlag }) {
  const toast = useToast();
  const [sharedSpaceData, setSharedSpaceData] = useState([]);

  useEffect(() => {
    loadShareContent();
  }, [shared_space_id]);

  // External Content
  const loadShareContent = () => {
    if (type === "external") {
        const params = {
          shared_space_key: shared_space_id,
          user_email: email,
          password: code,
        };
        const obj = {
          // filter_type: "string",
          records_per_page: 10,
          page_id: 1,
        };
        externalContentList(params, obj)
          .then((res) => {
            if (res?.data?.[0]?.pagination_data?.[0]?.records?.length) {
              setSharedSpaceData(res?.data?.[0]?.pagination_data?.[0]?.records);
            } else {
              setSharedSpaceData([]);
            }
          })
          .catch((err) => {
            if (err) {
              toast(toastFunctionToaster(err?.message, "error"));
            }
          });
      } else {
        let obj = {
          shared_space_uuid: shared_space_id,
          filter_enabled: filterEnabledFlag,
          // filter_type: "string",
          records_per_page: 10,
          page_id: 1,
        };
        fetchSharedSpaceData(obj)
          .then((res) => {
            if (res?.data?.[0]?.pagination_data?.[0]?.records?.length) {
              setSharedSpaceData(res?.data?.[0]?.pagination_data?.[0]?.records);
            } else {
              setSharedSpaceData([]);
            }
          })
          .catch((err) => {
            if (err) {
              toast(toastFunctionToaster(err?.message, "error"));
            }
          });
      }
  };

  function DisplaySharedContent({ sharedContentData }) {
    if (sharedContentData?.type === "image") {
      return (
        <Box
          border="1px"
          borderRadius={"5px"}
          borderStyle={"dotted"}
          borderColor={"#797b80"}
          p="10px"
        >
          <Heading
            fontSize={"18px"}
            color={"#fff"}
            fontWeight={"600"}
            mt="3"
          >
            Shared Image
          </Heading>
          <Grid gap={3} mt="2" templateColumns="repeat(4, 1fr)">
            {sharedContentData?.info && (
              <Img src={sharedContentData?.info?.[0]?.url} />
            )}
          </Grid>
        </Box>
      );
    } else if (sharedContentData?.type === "document") {
      return (
        <Box
          border="1px"
          borderRadius={"5px"}
          borderStyle={"dotted"}
          borderColor={"#797b80"}
          p="10px"
        >
          <Heading
            fontSize={"18px"}
            color={"#fff"}
            fontWeight={"600"}
            mt="3"
          >
            Attachment
          </Heading>
          <Link
            mt="2"
            href={sharedContentData?.info?.[0]?.url}
            color={"#fff"}
            fontWeight={"600"}
            target="_blank"
          >
            {sharedContentData?.info?.[0]?.object_name}
          </Link>
        </Box>
      );
    } else if (sharedContentData?.type === "comment") {
      return (
        <Box
          mt="2"
          border="1px"
          borderRadius={"5px"}
          borderStyle={"dotted"}
          borderColor={"#797b80"}
          p="10px"
        >
          <Text
            rows="3"
            resize="none"
            color={"#fff"}
            borderColor={"#797b80"}
          >
            {sharedContentData?.info}
          </Text>
          {sharedContentData?.updated !== sharedContentData?.created && (
            <Text textAlign={"right"} color={"#d7d7d7"} my="2">
              Edited by {sharedContentData?.author} on {sharedContentData?.updated}
            </Text>
          )}
        </Box>
      );
    }
  }

  // Todo:Shared Content HOC (High-Order) Component
  function SharedSpaceItem({ sharedSpaceData, reloadShareContent }) {
    const [childSharedSpaceData, setChildSharedSpaceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [childLoading, setChildLoading] = useState(false);

    const updateSharedContentStatus = (sharedContentId, newStatus) => {
      const obj = {
        shared_content_uuid: sharedContentId,
        shared_content_status: newStatus,
      }
      setLoading(true);
      handleSharedContentStatus(obj)
        .then((res) => {
          if (res?.data?.length) {
            toast(toastFunctionToaster(res?.message, "success"));
            reloadShareContent();
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          if (err) {
            toast(toastFunctionToaster(err?.message, "error"));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    const fetchChildDataDetails = () => {
      if (type === "external") {
        const params = {
          shared_space_key: shared_space_id,
          user_email: email,
          password: code,
        };
        const obj = {
          parent_shared_content_uuid: sharedSpaceData?.shared_content_uuid,
          // filter_type: "string",
          records_per_page: 10,
          page_id: 1,
        };
        setChildLoading(true);
        externalContentList(params, obj)
          .then((res) => {
            if (res?.data?.[0]?.pagination_data?.[0]?.records?.length) {
              setChildSharedSpaceData(res?.data?.[0]?.pagination_data?.[0]?.records);
            } else {
              setChildSharedSpaceData([]);
            }
          })
          .catch((err) => {
            if (err) {
              toast(toastFunctionToaster(err?.message, "error"));
            }
          })
          .finally(() => {
            setChildLoading(false);
          });
      } else {
        let obj = {
          shared_space_uuid: shared_space_id,
          parent_shared_content_uuid: sharedSpaceData?.shared_content_uuid,
          filter_enabled: filterEnabledFlag,
          // filter_type: "string",
          records_per_page: 10,
          page_id: 1,
        };
        setChildLoading(true);
        fetchSharedSpaceData(obj)
          .then((res) => {
            if (res?.data?.[0]?.pagination_data?.[0]?.records?.length) {
              setChildSharedSpaceData(res?.data?.[0]?.pagination_data?.[0]?.records);
            } else {
              setChildSharedSpaceData([]);
            }
          })
          .catch((err) => {
            if (err) {
              toast(toastFunctionToaster(err?.message, "error"));
            }
          })
          .finally(() => {
            setChildLoading(false);
          });
      }
    }

    useEffect(() => {
      fetchChildDataDetails();
    }, []);

    return (
        <Accordion
          defaultIndex={[0]}
          allowMultiple
          onChange={(isExpanded) => {
            if (isExpanded?.length) {
              fetchChildDataDetails();
            }
          }}
        >
          <AccordionItem
            borderWidth={"1px"}
            borderRadius={"5px"}
            borderColor={greyBgColor}
            mb="3"
          >
            <AccordionButton>
              <Flex
                bg={greyBgColor}
                w="100%"
                p="5px"
                alignItems={"center"}
                borderTopLeftRadius={"5px"}
                borderBottomLeftRadius={"5px"}
              >
                <Tooltip
                  placement="left-end"
                  label={sharedSpaceData?.author}
                >
                  <Avatar
                    name={sharedSpaceData?.author}
                    color={"#000"}
                    mr="2"
                  />
                </Tooltip>
                <Box
                  as="span"
                  borderRadius={"5px"}
                  fontWeight={"600"}
                  flex="1"
                  textAlign="left"
                  color={"#fff"}
                >
                  {sharedSpaceData?.created}
                </Box>
                {type !== "external" && (
                  <Switch
                    ml="2"
                    colorScheme="green"
                    isChecked={sharedSpaceData?.enabled}
                    onChange={(e) => {
                      e.stopPropagation();
                      updateSharedContentStatus(
                        sharedSpaceData?.shared_content_uuid,
                        !sharedSpaceData?.enabled,
                      );
                    }}
                  />
                )}
              </Flex>
              <AccordionIcon fontSize="2rem" color={"#fff"} ml="2" p="5px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Box ml="2rem">
                {loading ?
                  <Spinner color="#fff"/> :
                  <DisplaySharedContent sharedContentData={sharedSpaceData}/>
                }
                <Flex
                  border="1px"
                  borderRadius={"5px"}
                  borderStyle={"dotted"}
                  borderColor={"#797b80"}
                  p="10px"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mt="2"
                >
                  <UploadSharedContent
                    type={type}
                    sharedSpaceId={shared_space_id}
                    parentSharedContentId={sharedSpaceData?.shared_content_uuid}
                    email={email}
                    code={code}
                    uploadImageDetailsFlag={fetchChildDataDetails}
                  />
                </Flex>
                {childLoading ? (
                  <Box mt="2" ml="4">
                    <Spinner color="#fff"/>
                  </Box>
                ) : (
                  childSharedSpaceData?.length > 0 && (
                    <Box mt="2" ml="4">
                      {childSharedSpaceData.map((childData, index) => (
                        <SharedSpaceItem
                          key={index}
                          sharedSpaceData={childData}
                          reloadShareContent={fetchChildDataDetails}
                        />
                      ))}
                    </Box>
                  )
                )}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
    );
  }

  // Todo:Render History Component With Nested Child Component
  const renderSharedSpaceData = () => {
    if (sharedSpaceData?.length) {
      return sharedSpaceData.map((data, index) => (
        <SharedSpaceItem
          key={index}
          sharedSpaceData={data}
          reloadShareContent={loadShareContent}
        />
      ));
    }
    return (
      <Text
        p="2"
        bg={greyBgColor}
        mb="3"
        borderRadius={"5"}
        color={"white"}
        fontWeight={"600"}
      >
        No Shared Content Found
      </Text>
    );
  };

  return (
    <Box
      mb="3"
      h="calc(100vh - 15vh)"
      overflow="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "transparent",
          borderRadius: "24px",
        },
      }}
    >
      <Flex
        border="1px"
        borderRadius={"5px"}
        borderStyle={"dotted"}
        borderColor={"#797b80"}
        p="10px"
        justifyContent={"space-between"}
        alignItems={"center"}
        mb="2"
      >
        <UploadSharedContent
          type={type}
          sharedSpaceId={shared_space_id}
          email={email}
          code={code}
          uploadImageDetailsFlag={loadShareContent}
        />
      </Flex>
      {renderSharedSpaceData()}
    </Box>
  );
}

export default SharedFolder;
