// React Chakra Import
import { useSelector } from "react-redux";
import { MdLocalLibrary } from "react-icons/md";
import { React, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import {
  Flex,
  Box,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

// Component Import
import SharedFolder from "./SharedFolder";
import SharedDetails from "./SharedDetails";
import SideMenu from "../SideMenu/SideMenu";
import { greyBgColor, themeColor } from "../../constants";
import SharedSpaceAuth from "./SharedSpaceAuth";

function SharedSpace({ spaceData, codeRequired }) {
  const [searchParams] = useSearchParams();
  const [sharedSpaceCode, setSharedSpaceCode] = useState(searchParams.get("code"));
  const [sharedSpaceEmail, setSharedSpaceEmail] = useState(searchParams.get("email"));
  const sharedSpaceKey = searchParams.get("key");
  const [isCodeCorrect, setIsCodeCorrect] = useState(false);
  const sideMenuStatus = useSelector((store) => store.notificationHeader);
  const { sideMenuFlag } = sideMenuStatus;
  const [configurationCollapseFlag, setConfiguationCollapseFlag] =
    useState(true);

  return (
    <Flex
      bg="#151117"
      w="100%"
      fontSize="13px"
      height="100vh"
      overflow="hidden"
    >
      {isCodeCorrect ? (
        <>
          <Box
            w={sideMenuFlag ? "15%" : "5%"}
            color="#f1f0f5"
            overflow="hidden"
          >
            <SideMenu showMenu={false}></SideMenu>
            {/*<Link
              as={RouterLink}
              to={"/sharedSpace/6ef1bd95-6416-4461-903f-c5485e71617a"}
              _focus={{ boxShadow: "none" }}
              style={{ textDecoration: "none" }}
              w="100%"
            >
              <Flex alignItems="center" p="2" borderRadius="5px">
                <Icon
                  mx="5px"
                  mr="5"
                  fontSize={sideMenuFlag ? "25" : "36"}
                  fontWeight="600"
                  _groupHover={{ color: "white" }}
                  as={MdLocalLibrary}
                />
                Dummy
              </Flex>
            </Link>
            <Link
              as={RouterLink}
              to={"/sharedSpace/6ef1bd95-6416-4461-903f-c5485e71617a"}
              _focus={{ boxShadow: "none" }}
              style={{ textDecoration: "none" }}
              w="100%"
            >
              <Flex alignItems="center" p="2" borderRadius="5px">
                <Icon
                  mx="5px"
                  mr="5"
                  fontSize={sideMenuFlag ? "25" : "36"}
                  fontWeight="600"
                  _groupHover={{ color: "white" }}
                  as={MdLocalLibrary}
                />
                Dummy
              </Flex>
            </Link>*/}
          </Box>
          <Box
            w={sideMenuFlag ? "85%" : "95%"}
            bg={greyBgColor}
            m="0.5%"
            borderRadius="10px"
            p="10px"
            h="98%"
            overflowY="scroll"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: greyBgColor,
                borderRadius: "24px",
              },
            }}
          >
            <Flex h="100%">
              <Box
                w={!configurationCollapseFlag ? "95%" : "70%"}
                bg={"#151117"}
                p="3"
                borderRadius={"10px"}
                overflow="hidden"
              >
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mb="2"
                >
                  <Text
                    mb="2"
                    fontSize={"18px"}
                    fontWeight={"600"}
                    color={themeColor}
                  >
                    {spaceData?.name}
                  </Text>
                </Flex>
                <SharedFolder
                  type="external"
                  shared_space_id={sharedSpaceKey}
                  email={sharedSpaceEmail}
                  code={sharedSpaceCode}
                />
              </Box>
              {configurationCollapseFlag ? (
                <Box
                  w="30%"
                  position="relative"
                  p="3"
                  borderRadius="15px"
                  bg="#151117"
                  ml="2"
                >
                  <Box
                    position="absolute"
                    top="0"
                    right="0"
                    mr="2"
                    mt="3"
                    zIndex="999"
                  >
                    <AiFillRightCircle
                      color="#fff"
                      cursor="pointer"
                      size="20px"
                      bg={greyBgColor}
                      onClick={() => {
                        setConfiguationCollapseFlag(!configurationCollapseFlag);
                      }}
                    />
                  </Box>
                  <Box
                    w="100%"
                    h="95%"
                    overflow={"hidden"}
                    overflowY="auto"
                    css={{
                      "&::-webkit-scrollbar": {
                        width: "4px",
                      },
                      "&::-webkit-scrollbar-track": {
                        width: "6px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#151117",
                        borderRadius: "24px",
                      },
                    }}
                  >
                    <SharedDetails />
                  </Box>
                </Box>
              ) : (
                <Box
                  w="5%"
                  p="3"
                  borderRadius="10px"
                  bg="#151117"
                  h="100%"
                  ml="2"
                  pl="5"
                >
                  <Box w={"100%"} h="100%">
                    <AiFillLeftCircle
                      cursor="pointer"
                      color="#fff"
                      onClick={() => {
                        setConfiguationCollapseFlag(!configurationCollapseFlag);
                      }}
                      size="20px"
                    />
                    <Text
                      fontSize="16px"
                      color="#fff"
                      mt="2"
                      css={{
                        writingMode: "vertical-lr",
                        textOrientation: "mixed",
                      }}
                    >
                      Expand Shared Details
                    </Text>
                  </Box>
                </Box>
              )}
            </Flex>
          </Box>
        </>
      ) : (
        <SharedSpaceAuth
          sharedSpaceKey={sharedSpaceKey}
          email={sharedSpaceEmail}
          setEmail={setSharedSpaceEmail}
          code={sharedSpaceCode}
          setCode={setSharedSpaceCode}
          setIsCodeCorrect={setIsCodeCorrect}
        />
      )}
    </Flex>
  );
}

export default SharedSpace;
