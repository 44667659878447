// Chakra React Imports
import axios from "../../../../../services/axios";
import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { Box, Button, useToast, Input } from "@chakra-ui/react";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";

import { uploadShareSpaceContent } from "../../../../../services/businessServices";
import { externalContentUpload } from "../../../../../services/sharedServices";

function UploadSharedContent({
  type,
  sharedSpaceId,
  parentSharedContentId,
  email,
  code,
  uploadImageDetailsFlag,
}) {
  const toast = useToast();
  const [loading, isLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files?.[0]) {
      setUploadFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    handleSubmitNew();
  }, [uploadFile]);

  function postObjectData(s3Data, selectedFile) {
    isLoading(true);

    const { url, fields } = s3Data;
    const payload = new FormData();

    // add all the other fields
    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    payload.append("file", selectedFile);
    const { data: result } = axios
      .post(url, payload)
      .then((res) => {
        if (res?.data?.length > 0) {
          toast(toastFunctionToaster("File uploaded successfully", "success"));
          uploadImageDetailsFlag(true);
        } else {
          toast(toastFunctionToaster("File uploaded successfully", "success"));
          uploadImageDetailsFlag(true);
        }
      })
      .catch((err) => {
        if (err) {
          toast(toastFunctionToaster("Something Went Wrong", "error"));
        }
      })
      .finally(() => {
        isLoading(false);
      });
  }

  const handleSubmitNew = async () => {
    if (uploadFile) {
      isLoading(true);
      if (type === "external") {
        const params = {
          shared_space_key: sharedSpaceId,
          user_email: email,
          password: code,
        };
        let obj = {
          file_name: uploadFile.name,
          file_type: uploadFile.type,
          content_choice: "string",
        };
        if (parentSharedContentId) {
          obj.parent_shared_content_uuid = parentSharedContentId;
        }
        const response = await externalContentUpload(params, obj);
        if (
          response &&
          response?.data &&
          response?.data?.length > 0 &&
          response.data[0]?.signedPackage?.signedUrl
        ) {
          const finalUrl = response.data[0]?.signedPackage?.signedUrl;
          isLoading(false);
          const s3UploadResult = await postObjectData(finalUrl, uploadFile);
        } else {
          isLoading(false);
          toast(toastFunctionToaster(response?.message, "error"));
        }
      } else {
        let objData = {
          shared_space_uuid: sharedSpaceId,
          file_name: uploadFile.name,
          file_type: uploadFile.type,
          content_choice: "string",
        };
        if (parentSharedContentId) {
          objData.parent_shared_content_uuid = parentSharedContentId;
        }
        const response = await uploadShareSpaceContent(objData);
        if (
          response &&
          response?.data &&
          response?.data?.length > 0 &&
          response.data[0]?.signedPackage?.signedUrl
        ) {
          const finalUrl = response.data[0]?.signedPackage?.signedUrl;
          isLoading(false);
          const s3UploadResult = await postObjectData(finalUrl, uploadFile);
        } else {
          isLoading(false);
          toast(toastFunctionToaster(response?.message, "error"));
        }
      }
    }
  };

  return (
    <Box
      w="150px"
      position={"relative"}
      bg="#1c181e"
      h="100%"
      cursor={"pointer"}
    >
      <Input
        p="0px"
        type="file"
        onChange={handleFileChange}
        position="relative"
        opacity="0"
        h="100%"
        zIndex={"1"}
      ></Input>
      <Button
        size={"sm"}
        color={"gray.600"}
        onClick={handleSubmitNew}
        rightIcon={<FaPlus />}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        colorScheme="green"
        textColor={"#fff"}
        isDisabled={loading ? true : false}
        isLoading={loading ? true : false}
      >
        Add Attachment
      </Button>
    </Box>
  );
}

export default UploadSharedContent;
