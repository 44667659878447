// Chakra - React Imports
import { CgAsterisk } from "react-icons/cg";
import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Stack,
  Radio,
  Button,
  Drawer,
  useToast,
  RadioGroup,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
} from "@chakra-ui/react";

// Component Imports
import { errorColor } from "../../../../../constants";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";

// Api Imports
import { handleSpaceSecurity } from "../../../../../services/businessServices";

function ShareDetails({ shareSpaceDetails, fetchShareSpaceDetails }) {
  const toast = useToast();
  const btnRef = React.useRef();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(""); // Email validation error state
  const [loading, isLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [selectedSetting, setSelectedSetting] = useState("none");
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Email validation helper function
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Open Shared Space in new tab
  const handleOpenNewTab = () => {
    // Email validation
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else {
      setEmailError(""); // Clear error if email is valid
    }

    // Password validation for "password" setting
    if (selectedSetting === "password") {
      if (!password || !confirmPassword) {
        setPasswordError("Password and Confirm Password cannot be empty");
        return;
      }
      if (password !== confirmPassword) {
        setPasswordError("Passwords do not match");
        return;
      }
      setPasswordError(""); // Clear error if passwords match
    }

    // If everything is valid, call handleSpaceDetails
    handleSpaceDetails();
  };

  const handleSpaceDetails = () => {
    isLoading(true);
    let obj = {
      shared_space_uuid: shareSpaceDetails?.shared_space_uuid,
      shared_space_security_target: [email],
    };
    if (selectedSetting !== "none") {
      obj["shared_space_security_type"] = selectedSetting;
    }
    if (selectedSetting === "password") {
      obj["password"] = password;
    }
    console.log(obj);
    handleSpaceSecurity(obj)
      .then((res) => {
        isLoading(false);
        handleClose();
        if (res?.result) {
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        isLoading(false);
        if (err) {
          toast(toastFunctionToaster(err?.message, "error"));
        }
      });
  };

  // Handle validation and clearing passwords when changing settings
  useEffect(() => {
    if (selectedSetting !== "password") {
      setPassword("");
      setConfirmPassword("");
      setPasswordError("");
    }
  }, [selectedSetting]);

  useEffect(() => {
    setEmail(JSON.parse(shareSpaceDetails?.target)?.[0]);
    setSelectedSetting(shareSpaceDetails?.secured);
  }, [shareSpaceDetails])

  const handleClose = () => {
    onClose();
    setEmail("");
    setEmailError(""); // Clear email error on close
    setPassword("");
    setPasswordError("");
    setConfirmPassword("");
    fetchShareSpaceDetails();
  };

  return (
    <>
      <Button size={"xs"} ml="2" colorScheme="green" onClick={onOpen}>
        Share
      </Button>
      <Drawer
        onClose={() => {
          handleClose();
        }}
        size="md"
        isOpen={isOpen}
        placement="right"
        finalFocusRef={btnRef}
        fontFamily="Poppins"
      >
        <DrawerOverlay />
        <DrawerContent bg="#151117">
          <DrawerCloseButton color={"red.500"} />
          <DrawerHeader
            color={"#fff"}
            borderBottomWidth={"0.5px"}
            borderBottomColor={"#686868"}
          >
            {shareSpaceDetails?.shared ? "Update" : "Add"}{" "}
            Shared Space External Users
          </DrawerHeader>

          <DrawerBody mt="4">
            <Box>
              <Flex>
                <Text mb="8px" color={"#fff"}>
                  Email
                </Text>
                <CgAsterisk color={errorColor} />
              </Flex>
              <Input
                placeholder="Enter Email"
                size="md"
                mt="3"
                borderRadius="5px"
                color="#fff"
                fontSize="14px"
                borderColor="#494552"
                bg="#1c181e"
                _placeholder={{ color: "white" }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {emailError && (
                <Text color={errorColor} fontSize="sm" mt="1">
                  {emailError}
                </Text>
              )}
            </Box>
            <Box mt="4" bg="#000" borderRadius={"10px"} p="10px">
              <Text color={"#fff"} fontWeight={"600"}>
                Security Settings
              </Text>
              <RadioGroup
                defaultValue="2"
                mt="3"
                onChange={setSelectedSetting}
                value={selectedSetting}
              >
                <Stack spacing={5} direction="column" color="#fff">
                  <Radio value="none" colorScheme="green">
                    No Security
                  </Radio>
                  <Radio value="pin" colorScheme="green">
                    Send Email Code
                  </Radio>
                  <Radio value="password" colorScheme="green">
                    Password
                  </Radio>
                </Stack>
              </RadioGroup>
              {selectedSetting === "password" ? (
                <Box ml="6">
                  <Input
                    mt="3"
                    color="#fff"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Set Password"
                  />
                  <Input
                    mt="3"
                    color="#fff"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm Password"
                  />
                </Box>
              ) : null}
              {passwordError && (
                <Text ml="6" fontSize="sm" color={errorColor} mt="1">
                  {passwordError}
                </Text>
              )}
            </Box>
          </DrawerBody>

          <DrawerFooter borderTopWidth={"0.5px"} borderTopColor={"#686868"}>
            <Button colorScheme="red" size="sm" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              size="sm"
              isDisabled={loading ? true : false}
              isLoading={loading ? true : false}
              onClick={handleOpenNewTab}
            >
              Share
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ShareDetails;
