// Chakra Import
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Text,
  Box,
  Flex,
  Spinner,
  useToast,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";

// Component Import
import CardFlip from "./CardFlip";
import ImageGenerationConfig from "../../ImageGenerationConfig";
import ResponsivePagination from "react-responsive-pagination";
import { getEmailId } from "../../../../../utils/localStorageIndex";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import { defaultThemeColor, greyBgColor } from "../../../../../constants";

// Api Import
import { maskRegionColors } from "../../../../../constants/Workflow";
import { setTabFlag } from "../../../../../store/actions/workFlowAction";
import {
  CollectPreviousData,
  setupReferenceObject,
} from "../../../../../services/projectTemplateService";

function PreviousImageConfig(props) {
  const toast = useToast();
  const dispatch = useDispatch();
  const { projectId, workflowId, tabFlag } = props;
  const [loading, isLoading] = useState(false);
  const [prevImageData, setPrevImageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);

  useEffect(() => {
    if (projectId && workflowId && tabFlag === 1) {
      renderPreviousImage();
    }
  }, [props?.tabFlag, currentPage]);

  const renderPreviousImage = () => {
    isLoading(true);
    let obj = {
      email: getEmailId(),
      project_uuid: projectId,
      workflow_uuid: workflowId,
      page_id: currentPage,
      records_per_page: 5,
    };
    CollectPreviousData(obj)
      .then((res) => {
        if (res?.data?.length) {
          setPrevImageData(res?.data[0]?.records);
          setTotalPages(res?.data[0]?.total_pages);
        } else {
          setPrevImageData([]);
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  useEffect(() => {
    renderCardFlip();
  }, [prevImageData]);

  const renderCardFlip = () => {
    if (prevImageData?.length) {
      return prevImageData.map((data, index) => renderImages(data, index));
    } else {
      return (
        <Text
          fontWeight="600"
          fontSize="16px"
          p="2"
          bg={greyBgColor}
          m="2"
          color="#fff"
        >
          No Data Found....
        </Text>
      );
    }
  };

  // Todo:Merge Json and Image into an array and render the image
  const renderImages = (data, outerIndex) => {
    const rowJsonData = data?.images[1][0]?.json;
    let imageJsonData = [];
    data?.images?.forEach((subData) => {
      imageJsonData.push(subData[0]);
    });

    const groupedArrays = imageJsonData.reduce(
      (result, currentObject, index, array) => {
        if (index % 2 === 0) {
          result.push([...array.slice(index, index + 2)]);
        }
        return result;
      },
      []
    );

    // Todo:Received Data From Like Component > Card Flip Component
    const resetAfterLikeImage = (value) => {
      if (value === "true") {
        renderPreviousImage();
      }
    };

    // Todo:Clone All Functionality
    const cloneFunctionality = (jsonData) => {
      const storeJsonData = jsonData;
      if (jsonData.model_sub_name === "inpaint_controlnet") {
        cloneGuidanceImage(
          storeJsonData?.images[0]?.control_image,
          storeJsonData?.images[0]?.control_image_box
        );
      }
      storeConfigData(storeJsonData);
      cloneAllRegionData(
        storeJsonData?.images?.[0]?.regions,
        jsonData.model_sub_name
      );
      dispatch(setTabFlag(0));
    };

    return (
      <>
        <Box
          textAlign="left"
          color={defaultThemeColor}
          p="2"
          fontSize="15px"
          fontWeight="600"
          borderWidth="0.5px"
          borderColor={"#585858"}
          key={`Title ${outerIndex}`}
        >
          <Flex justifyContent={"space-between"} px="2">
            <Text>
              Workflow Results (ID # {data?.folder}):{" "}
              {eval(data.images.length / 2)} Images
            </Text>
            <Button
              size={"xs"}
              colorScheme="purple"
              onClick={() => {
                cloneFunctionality(JSON.parse(rowJsonData));
              }}
            >
              Copy To Current Design
            </Button>
          </Flex>
        </Box>
        <Box
          borderWidth={"0.5px"}
          borderColor={"#585858"}
          borderTop={"0px"}
          key={outerIndex}
          pt="3"
          mb="3"
        >
          <Box
            display={"-webkit-box"}
            overflow="auto"
            overflowY="hidden"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#fff",
                borderRadius: "10px",
              },
            }}
            cursor="pointer"
          >
            {groupedArrays?.map((value, index) => (
              <CardFlip
                key={outerIndex + index}
                frontImage={value}
                likeRating={data?.star_data ? data?.star_data : []}
                cardFlipStatus={true}
                indexValue={index}
                outerIndexValue={outerIndex}
                workflowDetails={workflowDetails}
                workflowCounter={data?.folder}
                sendDataToPrevImageComp={resetAfterLikeImage}
                imageJsonData={prevImageData}
              />
            ))}
          </Box>
          <Accordion allowMultiple>
            <AccordionItem border={"0px"}>
              <AccordionButton justifyContent={"end"} mt="2">
                <Button size="xs" colorScheme="yellow">
                  More Info
                </Button>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <ImageGenerationConfig
                  rowJsonData={rowJsonData}
                  workFlowImage={props?.workFlowImage}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </>
    );
  };

  // Todo:Clone Configurations
  const storeConfigData = (data) => {
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "guidanceScale", value: data?.guidance_scale },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageSharpness", value: data?.alpha_sharpness },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "inferenceSteps", value: data?.num_inference_steps },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "guidanceType", value: data?.controlnet_type },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "conditionScale",
        value: data?.controlnet_conditioning_scale,
      },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "inpaintStrength", value: data?.inpaint_strength },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "maskCropPadding", value: data?.mask_crop_padding },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "refAdainScale",
        value: data?.ref_adain_conditioning_scale,
      },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "refAttnScale",
        value: data?.ref_attn_conditioning_scale,
      },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageWidth", value: data?.width },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageHeight", value: data?.height },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageSeed", value: data?.seed },
    });
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "generatedImageCount",
        value: data?.num_images_per_prompt,
      },
    });
    dispatch(setTabFlag(0));
    toast(
      toastFunctionToaster(
        "Configuration Settings Copied Successfully",
        "success"
      )
    );
  };

  // Todo:Clone Guidance Image
  const cloneGuidanceImage = (image, box) => {
    const sliceValue = image.slice(image.indexOf(".com") + 5);
    let objImageInfo = {
      object_info: sliceValue,
    };
    setupReferenceObject(objImageInfo)
      .then((res) => {
        if (res?.result && res?.data[0]?.reference_status) {
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: {
              key: "control_guidance_image",
              value: res?.data?.[0]?.object?.[0]?.url,
            },
          });
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: {
              key: "control_guidance_image_name",
              value: sliceValue,
            },
          });
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: {
              key: "controlBoundingBox",
              value: box && box?.length === 4 ? box : null,
            },
          });
          dispatch(setTabFlag(0));
          toast(
            toastFunctionToaster(
              "Guidance Image Copied Successfully",
              "success"
            )
          );
        } else {
          toast(toastFunctionToaster("Something Went Wrong", "error"));
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      });
  };

  const cloneAllRegionData = async (promptData, modelEntity) => {
    const useBackground =
      modelEntity === "controlnet" || modelEntity === "text_to_image";
    let refImageArr = ["", "", "", "", ""];
    let refNameArr = ["", "", "", "", ""];
    let promptArr = ["", "", "", "", ""];
    let maskData = [null, null, null, null, null];

    for (let index = 0; index < promptData.length; index++) {
      const data = promptData[index];
      const refImageUrl = data?.ref_image;
      const newIndex =
        useBackground && index === promptData.length - 1
          ? maskRegionColors.length
          : index;
      if (refImageUrl) {
        try {
          const slicedUrl = refImageUrl?.slice(
            refImageUrl?.indexOf(".com") + 5
          );
          const res = await setupReferenceObject({ object_info: slicedUrl });
          if (res?.result && res?.data[0]?.reference_status) {
            refImageArr[newIndex] = res?.data?.[0]?.object?.[0]?.url;
            refNameArr[newIndex] = slicedUrl;
            promptArr[newIndex] = data?.prompt;
            maskData[newIndex] = data?.mask;
          } else {
            toast(toastFunctionToaster("Something Went Wrong", "error"));
            return;
          }
        } catch (err) {
          toast(toastFunctionToaster("Something Went Wrong", "error"));
          return;
        }
      } else {
        promptArr[newIndex] = data?.prompt;
        maskData[newIndex] = data?.mask;
      }
    }

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "maskData", value: maskData },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "reference_image", value: refImageArr },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "reference_name", value: refNameArr },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "promptData", value: promptArr },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "negativePrompt", value: promptData[0]?.negative_prompt },
    });

    dispatch(setTabFlag(0));

    toast(toastFunctionToaster("Region Data Copied Successfully", "success"));
  };

  return (
    <Box>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt="10" h="60vh">
          <Box textAlign={"center"}>
            <Spinner size="xl" color="#fff" />
            <Text color={defaultThemeColor} fontWeight={"600"} mt="2">
              Loading Previous Design Data...
            </Text>
          </Box>
        </Flex>
      ) : (
        <Box
          h="calc(100vh - 100px)"
          overflow="auto"
          overflowX="hidden"
          mt="3"
          css={{
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#fff",
              borderRadius: "24px",
            },
          }}
        >
          {renderCardFlip()}
          {prevImageData?.length ? (
            <Box mt="4">
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={(pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
              />
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
}

export default PreviousImageConfig;
