// Chakra - React Imports
import React from "react";
import { Box, Text } from "@chakra-ui/react";

// Component Imports
import { greyBgColor } from "../../constants";

function SharedDetails() {
  return (
    <Box>
      <Text color="#fff" textAlign={"center"} fontSize={"20px"}>
        Snapshot
      </Text>
      <Box
        color="#fff"
        borderWidth={"1px"}
        p="10px"
        borderRadius={"10px"}
        borderColor={greyBgColor}
        mt="3"
        fontSize={"14px"}
      >
        <Text fontWeight={"600"}>C- Entry 1 (Sep 30, 2024)</Text>
        <Text ml="5">- 6 Image, 1 PDF</Text>
        <Text ml="5">- Comment from Taylor</Text>
        <Text mt="3" fontWeight={"600"}>
          C- Entry 2 (Sep 27, 2024)
        </Text>
        <Text ml="5">2 Images added </Text>
        <Text mt="3" fontWeight={"600"}>
          U - Entry 3 (Sep 20, 2024)
        </Text>
        <Text ml="5">Comment added </Text>
        <Text mt="3" fontWeight={"600"}>
          U - Entry 4 (Sep 16, 2024)
        </Text>
        <Text ml="5">Comment added </Text>
        <Text mt="3" fontWeight={"600"}>
          C - Entry 5 (Sep 12, 2024)
        </Text>
        <Text mt="3" fontWeight={"600"}>
          C - Entry 6 (Sep 11, 2024)
        </Text>
        <Text mt="3" fontWeight={"600"}>
          U- Entry 7 (Sep 03, 2024)
        </Text>
      </Box>
    </Box>
  );
}

export default SharedDetails;
