// React Chakra Imports
import { FaRegEdit, FaTimesCircle } from "react-icons/fa";
import { AiFillCalendar, AiFillMail } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import {
  Box,
  Tab,
  Tabs,
  Flex,
  Text,
  Switch,
  Button,
  Spinner,
  TabList,
  TabPanel,
  TabPanels,
  useToast,
} from "@chakra-ui/react";

// Component Imports
import ShareDetails from "./ShareDetails";
import { defaultThemeColor, greyBgColor } from "../../../../../constants";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";

// Api Imports
import {
  fetchShareSpaceData,
  manageShareSpace,
} from "../../../../../services/businessServices";
import UserSharedSpace from "../../../../SharedSpace/UserSharedSpace";

function ProjectShare({ projectId, editSpaceData, renderData, selectedIndex }) {
  const toast = useToast();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sharedData, setSharedData] = useState([]);
  const [sharedTabList, setSharedTabList] = useState([]);
  const [sharedLoader, setSharedLoader] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  // const [uploadContentFlag, setUploadContentFlag] = useState(null);
  const [shareManageSwitch, setSharedManageSwitch] = useState(null);

  useEffect(() => {
    if (projectId && selectedIndex === 1) {
      fetchShareSpaceDetails();
      setSharedTabList([]);
    }
  }, [projectId, selectedIndex, currentPage]);

  // Todo:Counter Updates Space Space once share space is created updated
  useEffect(() => {
    if (renderData > 1) {
      fetchShareSpaceDetails();
    }
  }, [renderData]);

  // Todo:Fetch Share Space List
  const fetchShareSpaceDetails = () => {
    setSharedLoader(true);
    let obj = {
      project_uuid: projectId,
      filter_enabled: false,
      records_per_page: 10,
      page_id: currentPage,
    };
    fetchShareSpaceData(obj)
      .then((res) => {
        if (res?.data?.length) {
          setSharedData(res?.data[0]?.records);
          setTotalPages(res?.data[0]?.total_pages);
        } else {
          setSharedData([]);
          setTotalPages(0);
        }
      })
      .catch((err) => {
        if (err) {
          toast(toastFunctionToaster(err?.message, "error"));
        }
      })
      .finally(() => {
        setSharedLoader(false);
      });
  };

  const updateSharedStatus = (data) => {
    setSharedLoader(true);
    let obj = {
      shared_space_uuid: data?.shared_space_uuid,
      shared_space_status: !data?.status,
    };
    manageShareSpace(obj)
      .then((res) => {
        setSharedLoader(false);
        if (res?.result) {
          fetchShareSpaceDetails();
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        setSharedLoader(false);
        if (err) {
          toast(toastFunctionToaster(err?.message, "error"));
        }
      });
  };

  // Open Shared Space in new tab
  const handleOpenNewTab = (data) => {
    console.log(data?.url);

    let url = data?.url;
    // url = url.replace("https://corbu.ai", "http://localhost:3000");
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // Todo:Render Share Space List
  const renderSharedSpaceData = () => {
    if (sharedData && sharedData.length > 0) {
      return sharedData.map((data, index) => (
        <Flex
          key={data?.shared_space_uuid}
          color="#fff"
          p="10px"
          borderBottomWidth={"1px"}
          borderBottomColor={greyBgColor}
          cursor={"pointer"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Flex alignItems={"center"}>
            <Flex alignItems={"center"}>
              <Text
                fontSize={"16px"}
                w="200px"
                mb="0"
                onClick={() => {
                  tabShare(data);
                }}
              >
                {data?.name}
              </Text>
            </Flex>
            <Flex alignItems={"center"} ml="2">
              <Flex ml="4">
                <AiFillCalendar fontSize={"16px"} />
                <Text as="span" ml="1">
                  {data?.started || "-"}
                </Text>
              </Flex>
              <Flex ml="4">
                <AiFillMail fontSize={"16px"} />
                <Text as="span" ml="1">
                  {data?.target ? JSON.parse(data?.target).join(", ") : "-"}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex alignItems={"center"}>
            {data?.shared && data?.status && (
              <Flex
                mx="3"
                border={"1px"}
                alignItems={"center"}
                borderRadius={"5px"}
                borderColor={"#464646"}
              >
                <Text px="2" textAlign={"center"}>
                  1111
                </Text>
                <Button
                  size="xs"
                  borderRadius={"0px"}
                  borderTopRightRadius={"5px"}
                  borderBottomRightRadius={"5px"}
                  colorScheme="purple"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenNewTab(data);
                  }}
                >
                  Public View
                </Button>
              </Flex>
            )}
            <Switch
              isChecked={data?.status}
              onChange={(e) => {
                e.stopPropagation();
                updateSharedStatus(data);
              }}
              colorScheme="green"
              mr="2"
            />
            <FaRegEdit
              my="2"
              fontSize={"18px"}
              cursor={"pointer"}
              mx="2"
              onClick={(e) => {
                e.stopPropagation();
                editSpaceData(data);
              }}
            />
            <ShareDetails
              shareSpaceDetails={data}
              fetchShareSpaceDetails={fetchShareSpaceDetails}
            />
          </Flex>
        </Flex>
      ));
    } else {
      return (
        <Text bg={greyBgColor} color="#fff" p="2" borderRadius={"5"}>
          No Shared Spaces Found
        </Text>
      );
    }
  };

  const tabShare = (data) => {
    setSharedTabList((prevData) => {
      let shareArr = [...prevData];
      const isDuplicate = shareArr.some(
        (item) => item.shared_space_uuid === data?.shared_space_uuid
      );
      // If not a duplicate, push the new data
      if (!isDuplicate) {
        shareArr.push(data);
      }
      setSelectedTabIndex(shareArr.length);
      return shareArr;
    });
  };

  const removeSelectedTab = (data, render) => {
    setSharedTabList((prevData) => {
      const newTabList = prevData.filter(
        (item) => item.shared_space_uuid !== data?.shared_space_uuid
      );

      setSelectedTabIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      return newTabList;
    });
  };

  const renderSharedData = () => {
    if (sharedTabList?.length) {
      return (
        <Tabs
          index={selectedTabIndex}
          onChange={(index) => setSelectedTabIndex(index)}
        >
          <TabList>
            <Tab
              _selected={{
                color: defaultThemeColor,
                fontWeight: 600,
                borderBottomColor: defaultThemeColor,
              }}
              color={"#fff"}
            >
              Share List
            </Tab>
            {sharedTabList.map((data, index) => (
              <Tab
                key={index}
                color={"#fff"}
                _selected={{
                  color: defaultThemeColor,
                  fontWeight: 600,
                  borderBottomColor: defaultThemeColor,
                }}
              >
                <Flex alignItems={"center"}>
                  <Text>{data.name}</Text>
                  <Box
                    bg="red.200"
                    borderRadius={"50px"}
                    ml="2"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSelectedTab(data);
                    }}
                  >
                    <FaTimesCircle color="red" fontSize={"12px"} />
                  </Box>
                </Flex>
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            <TabPanel p="0">{renderSharedSpaceData()}</TabPanel>
            {sharedTabList.map((data, index) => (
              <TabPanel key={index} px="0">
                <UserSharedSpace
                  spaceData={data}
                  manageSwitch={setSharedManageSwitch}
                  manageUploadedContent={fetchShareSpaceDetails}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      );
    }
    return null;
  };

  useEffect(() => {
    if (shareManageSwitch !== null || shareManageSwitch !== undefined) {
      removeSelectedTab(shareManageSwitch, "render");
      setSharedManageSwitch(null);
    }
  }, [shareManageSwitch]);

  return (
    <Flex
      h="calc(100vh - 15vh)"
      bg={"#151117"}
      p="3"
      borderRadius={"10px"}
      overflow="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: { greyBgColor },
          borderRadius: "24px",
        },
      }}
    >
      {sharedLoader ? (
        <Flex alignItems="center" justifyContent="center" h="60vh" w="100%">
          <Spinner size="xl" color="#fff" />
        </Flex>
      ) : (
        <Box w={"100%"}>
          <Box
            bg={"#151117"}
            borderRadius={"20px"}
            overflow="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: { greyBgColor },
                borderRadius: "24px",
              },
            }}
          >
            {sharedTabList?.length ? (
              renderSharedData()
            ) : (
              <>
                <Text
                  fontWeight={"600"}
                  fontSize={"20px"}
                  mb="2"
                  pl="2"
                  pb="2"
                  color="#fff"
                  borderBottom={"1px"}
                  borderBottomColor={greyBgColor}
                >
                  Shared List
                </Text>
                {sharedLoader ? (
                  <Flex
                    alignItems="center"
                    h="60vh"
                    color={"#fff"}
                    justifyContent="center"
                    mt="10"
                  >
                    <Spinner size="xl" />
                  </Flex>
                ) : (
                  <>{renderSharedSpaceData()}</>
                )}
              </>
            )}
          </Box>
          {totalPages > 1 ? (
            <Box my="4">
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={(pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
              />
            </Box>
          ) : null}
        </Box>
      )}
    </Flex>
  );
}

export default ProjectShare;
